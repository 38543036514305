import React from "react"

import Layout from "../components/layout"
import ServicesHome from "../components/marketing/services-home"
import SEO from "../components/seo"

const ServicesHomePage = () => (
  <Layout>
    <SEO title="Home Disposal Services" />
    <ServicesHome/>
  </Layout>
)

export default ServicesHomePage

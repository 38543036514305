import React, { Component } from "react";
// import { Link, navigate } from 'gatsby'
import Header from "./header"
import UpgradeSpace from "../../images/upgrade-space.png";
import Yard10 from "../../images/yard-10.svg";
import Yard15 from "../../images/yard-15.svg";
import Yard20 from "../../images/yard-20.svg";
import Yard30 from "../../images/yard-30.svg";
import LiveLoad from "../../images/live-load.svg";
import HowWork from "../../images/how-we-work.png";
import TextOrder from "../../images/text-order.svg";
import RealTimeLocator from "../../images/real-time-locator.svg";
import HighRates from "../../images/high-rates.svg";
import Reliability from "../../images/reliability.svg";
import Sustainability from "../../images/sustainability.svg";
import Transparency from "../../images/transparency.svg";
import QuoteForm from "./quoteForm";

class ServicesHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
       <Header/>
        <div className="services-section">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-5 offset-lg-1">
                <div className="services-upgrade-space">
                  <h1>Upgrade your living space.</h1>
                  <p>
                    Whether cleaning out or renovating your home, upgrade the
                    process by using our disposal services. Curbside is the only
                    tech enabled full service disposal company that can help you
                    make the environmentally friendly, and cost effective
                    decision.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <img className="img-fluid" src={UpgradeSpace} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section-disposal pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title text-center mb-3">
                  Disposal Options
                </h2>
                <p className="section-subtitle text-center mb-5 mx-auto">
                  We provide a range of container sizes designed for various
                  removal needs. We even offer Live Loads which are ideal for
                  smaller use cases or when a site has limited space for a
                  container.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="disposal-feature-list">
                  <li>
                    <div>
                      <div className="disposal-card--icon">
                        <img src={Yard10} alt="" />
                      </div>
                      <h4>10 Yards</h4>
                      <p>Size: 11 L x 8 W x 4 H ft</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div className="disposal-card--icon">
                        <img src={Yard15} alt="" />
                      </div>
                      <h4>15 Yards</h4>
                      <p>Size: 18 L x 8 W x 4 H ft</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div className="disposal-card--icon">
                        <img src={Yard20} alt="" />
                      </div>
                      <h4>20 Yards</h4>
                      <p>Size: 22 L x 8 W x 4 H ft </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div className="disposal-card--icon">
                        <img src={Yard30} alt="" />
                      </div>
                      <h4>30 Yards</h4>
                      <p>Size: 22 L x 8 W x 6 H ft </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div className="disposal-card--icon">
                        <img src={LiveLoad} alt="" />
                      </div>
                      <h4>Live Load</h4>
                    </div>
                  </li>
                </ul>

                <hr />
              </div>
            </div>
          </div>
        </section>

        <section className="section-left-right">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-md-6">
                <div className="">
                  <img className="img-fluid" src={HowWork} alt="" />
                </div>
              </div>
              <div className="col-md-5">
                <h2 className="section-title mb-3">How it Works</h2>
                <p className="section-subtitle mb-5">
                  Our transparent process is inspired by the needs of a great
                  customer experience. Schedule a consultation to assess your
                  needs, and simply text to order the container that best fits.{" "}
                </p>
                <ul className="feature-list">
                  <li>
                    <div className="feature-list--icon">
                      <img src={TextOrder} alt="" />
                    </div>
                    <div className="feature-list--text">
                      Expert <br /> consulation
                    </div>
                  </li>
                  <li>
                    <div className="feature-list--icon">
                      <img src={RealTimeLocator} alt="" />
                    </div>
                    <div className="feature-list--text">
                      On time <br /> delivery
                    </div>
                  </li>
                  <li>
                    <div className="feature-list--icon">
                      <img src={HighRates} alt="" />
                    </div>
                    <div className="feature-list--text">
                      Replacement <br /> or Recovery
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <hr />
              </div>
            </div>
          </div>
        </section>

        <section className="section-curbside">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="section-title text-center mb-3">Why Curbside</h2>
                <p className="section-subtitle text-center mb-5 mx-auto">
                  Curbside was built to handle large volume debris removal needs.
                  We are the only debris company using technology solutions like
                  live fleet tracking, and text to order.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="curbside-card">
                  <div className="curbside-card--icon">
                    <img src={Reliability} alt="" />
                  </div>
                  <h4>Reliability</h4>
                  <p>
                    Your dumpster is monitored throughout the process for a
                    great customer experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="curbside-card">
                  <div className="curbside-card--icon">
                    <img src={Sustainability} alt="" />
                  </div>
                  <h4>Sustainability</h4>
                  <p>
                    We bring all materials to a sorting station to ensure a high
                    rate of recycling.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="curbside-card">
                  <div className="curbside-card--icon">
                    <img src={Transparency} alt="" />
                  </div>
                  <h4>Transparency</h4>
                  <p>
                    All sorting data is shared so you can rest assure we did our
                    part responsibly.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <hr />
              </div>
            </div>
          </div>
        </section>
        <QuoteForm heading="Request a Quote"/>
      </div>
    );
  }
}

export default ServicesHomePage;
